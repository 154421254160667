import React, { useState, useEffect } from "react";
import axios from "axios";
import "./TableAnalysis.css";
import TableLoader from "./TableLoader";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { serverDjangoURL } from "variables/ServerURL";

let level = {
  1: "Low",
  2: "Medium",
  3: "High",
};

const QuestionWiseAnalysis = ({ testId, userId }) => {
  const [questionWiseData, setQuestionWiseData] = useState([]);
  const [questionWiseDataLoading, setQuestionWiseDataLoading] = useState(true);
  const [questionWiseDataError, setQuestionWiseDataError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  useEffect(() => {
    fetchQuestionWiseAnalysis(testId, userId);
  }, [testId, userId]);

  const fetchQuestionWiseAnalysis = async (testId, userId) => {
    setQuestionWiseDataLoading(true);
    try {
      const response = await axios.post(
        `${serverDjangoURL}/api/search/getQuestionWiseTestAnalysis/`,
        {
          userId: userId,
          testId: testId,
        }
      );
      if (response?.data?.data) {
        setQuestionWiseData(response?.data?.data ? response?.data?.data : []);
      }
    } catch (error) {
      setQuestionWiseDataError("Failed to fetch QuestionWise data");
    } finally {
      setQuestionWiseDataLoading(false);
    }
  };

  const formatQuestionText = (questionText) => {
    const hasImage = questionText.includes("<img");
    if (hasImage) {
      return "Image Question";
    }
    const plainText = questionText.replace(/<[^>]*>/g, "");
    const truncatedText =
      plainText.length > 50 ? `${plainText.substring(0, 50)}...` : plainText;
    return truncatedText;
  };

  const handleQuestionClick = (question) => {
    setSelectedQuestion(question);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedQuestion(null);
  };
  const formatOptions = (optionsString) => {
    try {
      if (!optionsString) return null;

      // First parse to handle the string JSON
      const firstParse = JSON.parse(optionsString);
      // Second parse because the Options is also a string
      const options =
        typeof firstParse.Options === "string"
          ? JSON.parse(firstParse.Options)
          : firstParse.Options;

      if (!options.OptionItems || !Array.isArray(options.OptionItems)) {
        return null;
      }

      return (
        <div className="options-container" style={{ marginTop: "15px" }}>
          {options.OptionItems.map((option) => (
            <div
              key={option.Id}
              className="option-item"
              style={{
                display: "flex",
                marginBottom: "10px",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  marginRight: "10px",
                  fontWeight: "bold",
                  minWidth: "25px",
                }}
              >
                {option.Id}.
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: option.Value,
                }}
              />
            </div>
          ))}
        </div>
      );
    } catch (error) {
      console.error("Error parsing options:", error);
      return null;
    }
  };

  return (
    <div className="table-container">
      {questionWiseDataLoading ? (
        <TableLoader
          heading={"Question wise Analysis"}
          data={"Loading Question wise Analysis data..."}
          loading={questionWiseDataLoading}
          error={questionWiseDataError}
        />
      ) : questionWiseDataError ? (
        <TableLoader
          heading={"Question wise Analysis"}
          data={"Failed to fetch Question wise Analysis  data"}
          loading={questionWiseDataLoading}
          error={questionWiseDataError}
        />
      ) : (
        <>
          <h2>Question wise Analysis</h2>
          <table>
            <thead>
              <tr>
                <th>Q No.</th>
                <th>Q Id.</th>
                <th>Attempt (%)</th>
                <th>Right (%)</th>
                <th>Wrong (%)</th>
                <th>Difficulty Level</th>
                <th>Correct Response</th>
                <th>Your Response</th>
                <th colSpan="3">Time Spent (Sec)</th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>You</th>
                <th>All Student Avg</th>
                <th>Top 10 Percentile Avg</th>
              </tr>
            </thead>
            <tbody>
              {questionWiseData.map((question, questionIndex) => {
                try {
                  return (
                    <tr key={questionIndex}>
                      <td>{questionIndex + 1}</td>
                      <td
                        style={{
                          width: "20%",
                          cursor: "pointer",
                        }}
                        onClick={() => handleQuestionClick(question)}
                      >
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "left",
                            maxWidth: "300px",
                            color: "#32b8b0",
                            textDecoration: "underline",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: formatQuestionText(
                              question?.questionDetail?.QuestionText || ""
                            ),
                          }}
                        />
                      </td>
                      <td>
                        {question?.attempt_percentage?.toFixed(2) ?? "N/A"}
                      </td>
                      <td>{question?.right_percentage?.toFixed(2) ?? "N/A"}</td>
                      <td>{question?.wrong_percentage?.toFixed(2) ?? "N/A"}</td>
                      <td>
                        {question?.difficulty_level[0]?.key
                          ? level[question?.difficulty_level[0].key]
                          : "NA"}
                      </td>
                      <td>{question?.questionDetail?.Answer ?? "N/A"}</td>
                      <td
                        style={{
                          backgroundColor: question?.user_performance
                            ?.is_correct
                            ? "#e6ffe6"
                            : "#ffe6e6",
                        }}
                      >
                        {question?.user_performance?.selected_answer ?? "N/A"}
                      </td>
                      <td> {question?.user_performance?.duration ?? "N/A"}</td>
                      <td>{question?.average_time_all_students ?? "N/A"}</td>
                      <td>
                        {question?.average_time_top_10_percentile ?? "N/A"}
                      </td>
                    </tr>
                  );
                } catch (error) {
                  console.error(`Error rendering row ${questionIndex}:`, error);
                  return (
                    <tr key={questionIndex}>
                      <td colSpan="8">Error rendering this row</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>

          <Dialog
            open={modalOpen}
            onClose={handleCloseModal}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                Question Details
                <span style={{ fontSize: "14px", color: "#666" }}>
                  Difficulty Level:{" "}
                  {selectedQuestion?.difficulty_level[0]?.key
                    ? level[selectedQuestion.difficulty_level[0].key]
                    : "NA"}
                </span>
              </div>
            </DialogTitle>
            <DialogContent>
              <div style={{ padding: "20px" }}>
                {/* Question Text */}
                <div style={{ marginBottom: "20px" }}>
                  <h4 style={{ marginBottom: "10px", color: "#333" }}>
                    Question:
                  </h4>
                  <div
                    style={{ marginLeft: "15px" }}
                    dangerouslySetInnerHTML={{
                      __html:
                        selectedQuestion?.questionDetail?.QuestionText || "",
                    }}
                  />
                </div>

                {/* Options */}
                {selectedQuestion?.questionDetail?.Options && (
                  <div style={{ marginBottom: "20px" }}>
                    <h4 style={{ marginBottom: "10px", color: "#333" }}>
                      Options:
                    </h4>
                    <div style={{ marginLeft: "15px" }}>
                      {formatOptions(selectedQuestion.questionDetail.Options)}
                    </div>
                  </div>
                )}

                {/* Answer */}
                {selectedQuestion?.questionDetail?.Answer && (
                  <div style={{ marginBottom: "20px" }}>
                    <h4 style={{ marginBottom: "10px", color: "#333" }}>
                      Correct Answer:
                    </h4>
                    <div
                      style={{
                        marginLeft: "15px",
                        padding: "10px",
                        backgroundColor: "#f5f5f5",
                        borderRadius: "4px",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: selectedQuestion.questionDetail.Answer,
                      }}
                    />
                  </div>
                )}

                {/* Additional Stats */}
                <div
                  style={{
                    marginTop: "20px",
                    padding: "15px",
                    backgroundColor: "#f8f8f8",
                    borderRadius: "4px",
                  }}
                >
                  <h4 style={{ marginBottom: "10px", color: "#333" }}>
                    Statistics:
                  </h4>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr",
                      gap: "15px",
                    }}
                  >
                    <div>
                      <strong>Attempt Rate:</strong>{" "}
                      {selectedQuestion?.attempt_percentage?.toFixed(2)}%
                    </div>
                    <div>
                      <strong>Success Rate:</strong>{" "}
                      {selectedQuestion?.right_percentage?.toFixed(2)}%
                    </div>
                    <div>
                      <strong>Error Rate:</strong>{" "}
                      {selectedQuestion?.wrong_percentage?.toFixed(2)}%
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default QuestionWiseAnalysis;
