import axios from "axios";
import { serverURL } from "variables/ServerURL";

export default function axiosConfig(baseUrl = serverURL) {
  const instance = axios.create({ baseURL: baseUrl });

  // Set up request interceptor for auth headers
  instance.interceptors.request.use((config) => {
    const token = sessionStorage.getItem("token");
    const AppId = sessionStorage.getItem("AppId");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    if (AppId) {
      config.headers["AppId"] = AppId;
    }

    return config;
  });

  // Set up response interceptor for handling unauthorized status
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (
        error.response &&
        (error.response.status === 401 || error.response.status === 405)
      ) {
        // Redirect to login page
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/"; // Adjust this URL as needed
      }
      return Promise.reject(error);
    }
  );

  return instance;
}
