import React, { useEffect } from 'react';
import PropTypes from "prop-types";

import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LockSharp from '@material-ui/icons/LockSharp';

// style
import useStyles from 'assets/jss/components/views/topicsContainerStyle'


const TopicAccordion = (props) => {
    const {
        expanded,
        onChange,
        heading,
        accordionDetails,
        lockDetails,
        ...rest
    } = props

    let lock = lockDetails === undefined  || lockDetails[heading] === undefined ? true : lockDetails[heading]

    const classes = useStyles();
    return (
        <Accordion
        disabled={!lock}
            expanded={expanded}
            onChange={onChange}
            className={classes.accordionClass} {...rest}>
            <AccordionSummary
                expandIcon={<><ExpandMoreIcon  /> </>}
                className={classes.accordionSummaryClass}
                classes={{ root: classes.accordionSummaryRoot }}
            >
                <div style={{display:'flex',alignItems:'center',width:'100%',justifyContent:'space-between'}}>
                    <Typography className={classes.heading}>{heading}</Typography>
                    {!lock ? <LockSharp/> : null}
                </div>
               
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
                {accordionDetails}
            </AccordionDetails>
        </Accordion>
    )
}

TopicAccordion.propTypes = {
    expanded: PropTypes.bool,
    onChange: PropTypes.func,
    heading: PropTypes.string,
    accordionDetails:PropTypes.node
}

export default TopicAccordion