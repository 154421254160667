import React, { useState, useEffect } from "react";

//Redux
import { useDispatch, useSelector } from "react-redux";
//import reducer
import * as actions from "components/Views/AssessmentTests/store/actions";

//@material-ui/icons
import InboxIcon from "@material-ui/icons/Inbox";
import ForumIcon from "@material-ui/icons/Forum";

// custom components
import PageNavigation from "components/CustomComponents/Navbars/PageNavigation";
import CustomTabs from "components/CustomComponents/CustomTabs/CustomTabs";

// Views
import OnlineTest from "./components/OnlineTestList";

const Container = (props) => {
  // type==0 -- ALL Test
  // type==1 -- OnGoing Test
  // type==2 -- UpComing Test
  // type==3 -- Past Test

  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.user.userinfo)
  const [selectedTab, setSelectedTab] = useState({
    name: "Ongoing",
    icon: InboxIcon,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (selectedTab.name === "Ongoing") fetchOnlineTest(1);
    if (selectedTab.name === "Upcoming") fetchOnlineTest(2);
    if (selectedTab.name === "Past") fetchOnlineTest(3);
  }, [selectedTab]);

  // fetch Online Test
  const fetchOnlineTest = async (tabtype, page = 1, pageSize = 10) => {
    await dispatch(actions.fetchOnlineAssignment(tabtype, page, pageSize,userInfo.userId))
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      {/*-----PageNavigation----- */}
      <PageNavigation logoText={"DashBoard"} back={"/assessment-dashboard"} />
      <div className="onlinetest-container background-home">
        <CustomTabs
          headerColor="warning"
          rtlActive
          onTabChange={(name) => setSelectedTab(name)}
          tabs={[
            {
              tabName: "Ongoing",
              tabContent: (
                <OnlineTest
                  loading={loading}
                  tabName={1}
                  fetchAssessments={fetchOnlineTest}
                />
              ),
            },
            {
              tabName: "Upcoming",
              tabContent: (
                <OnlineTest
                  loading={loading}
                  tabName={2}
                  fetchAssessments={fetchOnlineTest}
                />
              ),
            },
            {
              tabName: "Past",
              tabContent: (
                <OnlineTest
                  loading={loading}
                  tabName={3}
                  fetchAssessments={fetchOnlineTest}
                />
              ),
            },
          ]}
        ></CustomTabs>
      </div>
    </React.Fragment>
  );
};

export default Container;
