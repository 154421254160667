import React from "react";

function TableLoader({ heading, data, loading, error }) {
  return (
    <div
      style={{
        boxShadow: "0px 2px 22px 0px rgba(0, 0, 0, 0.12)",
        padding: "10px 0 10px 10px",
        marginBottom: "20px",
        backgroundColor: "#fff",
        width: "100%",
        height: "200px",
      }}
    >
      <h2 style={{ borderBottom: "1px solid #E9E9E9" }}>{heading}</h2>
      {loading ? (
        <div
          className=""
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="circle-loader"></div>
          <div style={{ textAlign: "center" }}>{data}</div>
        </div>
      ) : error ? (
        <div className="">{error}</div>
      ) : (
        ""
      )}
    </div>
  );
}

export default TableLoader;

// height: 100%;
// display: flex;
// flex-direction: column;
// justify-content: center;
// align-items: center;
