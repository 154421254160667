import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'
// import axios
import axiosConfig from "util/axios";
//validations
import { checkNull, checkEmail, checkMaxLength, checkMinLength } from 'util/validations'
//Common functions
import { clevertapEvents } from "util/commonfunctions";
//import Custom Components
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import AlertDialog from "components/CustomComponents/Dialog/AlertDialog";
import Button from "components/CustomComponents/CustomButtons/Button.js";
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import CardHeader from "components/CustomComponents/Card/CardHeader.js";
import { TextInput } from "components/CustomComponents/Inputs/Inputs"
import Progress from "components/CustomComponents/Progress/Progress"
// custom style
import useStyles from "assets/jss/components/views/userDetailsStyle";
//variables error
import { LENGTH_ERROR, MIN_LENGTH_ERROR } from 'variables/general'

const LoginDetails = (props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch()
  const classes = useStyles();

  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("success");
  const userInfo = useSelector(state => state.user.userinfo)


  const [state, setState] = useState({
    pincode: "",
    cityName: "",
    firstName: "",
    email: "",

    errorpincode: "",
    errorcityName: "",
    errorfirstName: "",
    erroremail: "",
  })

  const [userId, setUserId] = useState("")
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    setIsLoading(true)
    dispatch(questActions.initUserInfo()).then(() => {
      setIsLoading(false)
    }).catch(error => {
      setIsLoading(false)
      setAlertType("error")
      setAlertMessage(error.message)
    })
  }, [dispatch])



  useEffect(() => {

    if (userInfo.userId) {
      setUserId(userInfo.userId);
      setState(prvState => {
        return {
          ...prvState,
          pincode:userInfo.pincode,
          cityName:userInfo.cityName,
          firstName: userInfo.firstName,
          email: userInfo.emailId,
        }
      })
    }
  }, [userInfo])



  const validateInputs = async () => {
    let validationObject = {};
    checkNull("firstName", state.firstName, "Name is required", validationObject);
    checkNull("email", state.email, "Email is required", validationObject);
    checkEmail("email", state.email, "Email ID is not valid", validationObject);

    checkMaxLength("firstName", state.firstName, 50, LENGTH_ERROR(50), validationObject);
    checkMaxLength("pincode", state.pincode, 6, LENGTH_ERROR(6), validationObject);
    checkMaxLength("cityName", state.cityName, 50, LENGTH_ERROR(50), validationObject);
    checkMaxLength("email", state.email, 50, LENGTH_ERROR(50), validationObject);

    if (Object.keys(validationObject).length != 0) {
      setState({
        ...state,
        errorpincode: "",
        errorcityName: "",
        errorlastName: "",
        erroremail: "",
        ...validationObject
      });
    } else {
      handleRegisterUser();
    }
  }

  // Update User info
  const handleRegisterUser = () => {

    const userInfo = {
      'userId': userId,
      'firstName': state.firstName,
      'pincode': state.pincode,
      'cityName': state.cityName,
      'emailId': state.email
    }

    setIsLoading(true);

    dispatch(questActions.updateUserDetails(userInfo)).then(async response => {
      props.onUpdateDetails()
    }).catch(function (error) {
      setIsLoading(false);
      setAlertType("error")
      setAlertMessage(error.message)
    });
  }



  // Close Alert
  const onCloseAlert = () => {
    switch (alertType) {
      case "danger":
        setAlertMessage("")
        break;
      case "success":
        setAlertMessage("")
        props.handleNext()
        break;
      default:
        setAlertMessage("")
    }
  }




  return (
    <React.Fragment >
      <Progress open={isLoading} />


      <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
        <GridContainer className={classes.stepContent}>
          <AlertDialog
            maxWidth="xs"
            open={alertMessage ? true : false}
            type={alertType}
            ContentText={alertMessage}
            ContentTextStyle={{ color: 'black', marginBottom: 0 }}
            title={null}
            titleStyle={{ paddingBottom: 0 }}
            cancelable={() => { }}
            action={[{ text: 'OK', onPress: () => onCloseAlert() }
            ]}
          />

          <GridItem xs={12} sm={12} md={12}>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextInput
                  id="FIRST_NAME"
                  // autoFocus={true}
                  label="Name"
                  value={state.firstName}
                  errorText={state.errorfirstName}
                  onChange={(e) => setState({ ...state, firstName: e.target.value })}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextInput
                  label="Email ID"
                  value={state.email || ''} 
                  errorText={state.erroremail}
                  onChange={(e) => setState({ ...state, email: e.target.value })}
                />
              </GridItem>
            </GridContainer>


            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextInput
                  id="YOURCITY"
                  label="Your City"
                  value={state.cityName || ''}
                  errorText={state.errorcityName}
                  onChange={(e) => setState({ ...state, cityName: e.target.value })}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <TextInput
                  type="number"
                  className={classes.number}
                  label="Pincode"
                  value={state.pincode || ''}
                  errorText={state.errorpincode}
                  onChange={(e) => setState({ ...state, pincode: e.target.value })}
                  onInput={(e) => {
                    e.target.value = e.target.value ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 6) : ""
                  }}
                />
              </GridItem>
            </GridContainer>

          </GridItem>

          <GridItem xs={12} sm={12} md={12} className={classes.actionsGridItem}>
            <div className={classes.actionsContainer}>
              <Button
                round
                fullWidth
                color="primary"
                disabled={isLoading}
                onClick={validateInputs}
                className={classes.button}
              >
                Continue
                  </Button>

            </div>
          </GridItem>
        </GridContainer>


      </div>



    </React.Fragment>

  )
}

export default LoginDetails;