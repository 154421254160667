// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";

// core components
//  import Dashboard from '../components/Views/Dashboard/dashboard'
import Intermediate from "../components/Layouts/Intermediate";
import Home from "../components/Views/MainContainer/MainContainer";
import Nexool from "../components/Views/Nexool/Nexool";

import Tasks from "../components/Views/Tasks/Tasks";
import MobileTopics from "../components/Views/Tasks/Topics/MobileTopics";
import WebTopics from "../components/Views/Tasks/Topics/WebTopics";

import SignIn from "components/Views/SignIn/SigninSpectrum/signin";
import Signup from "../components/Views/SignUp/Signup";
import BoardGrade from "../components/Views/UserDetails/BoardGrade";
import UserDetails from "../components/Views/UserDetails/UserDetails";

import Instruction from "components/Views/ContentViewer/AssessmentViewer/Instruction";
import Assessment from "components/Views/ContentViewer/AssessmentViewer/Assessment";
import PDFViewer from "components/Views/ContentViewer/PDFViewer/PDFViewer";
import HTMLViewer from "components/Views/ContentViewer/HTMLViewer/HTMLViewer";
import HTMLContentViewer from "components/Views/ContentViewer/HTMLViewer/HTMLContentViewer";
import VideoViewer from "components/Views/ContentViewer/VideoViewer/VideoViewer";
import ActivityViewer from "components/Views/ContentViewer/ActivityViewer/ActivityViewer";
import TestViewer from "components/Views/ContentViewer/TestViewer/TestViewer";

import QuestCategory from "components/Views/QuestCategory/QuestCategory";
import QuestProducts from "components/Views/QuestProducts/QuestProducts";
import RaymondProducts from "components/Views/RaymondProducts";
import ShoppingCart from "components/Views/ShoppingCart/ShoppingCart";
import PGResponse from "components/Views/ShoppingCart/PGResponse";
import NotFound from "components/Views/NotFound";

import MissionsSchedules from "components/Views/MissionsSchedules/views";
import AssessmentTests from "components/Views/AssessmentTests";
import AssessmentTestReport from "components/Views/AssessmentTestReport";
import TestReport from "components/Views/AssessmentTestReport/TestReport";
import AssementDashBoard from "components/Views/AssementDashBoard";
const inventoryRoutes = [
  {
    path: "/signin",
    name: "SignIn",
    icon: DashboardIcon,
    component: SignIn,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/nexool",
    name: "Nexool",
    icon: DashboardIcon,
    component: Nexool,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/home",
    name: "Home",
    icon: DashboardIcon,
    component: Home,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/intermediate",
    name: "Intermediate",
    icon: DashboardIcon,
    component: Intermediate,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/category",
    name: "QuestCategory",
    icon: DashboardIcon,
    component: QuestCategory,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/products",
    name: "QuestProducts",
    icon: DashboardIcon,
    component: QuestProducts,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/raymond-products",
    name: "RaymondProducts",
    icon: DashboardIcon,
    component: RaymondProducts,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },

  {
    path: "/task",
    name: "Tasks",
    icon: DashboardIcon,
    component: Tasks,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },

  {
    path: "/assessment",
    name: "Assessment",
    icon: DashboardIcon,
    component: Assessment,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },

  {
    path: "/instruction",
    name: "Instruction",
    icon: DashboardIcon,
    component: Instruction,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/topics",
    name: "MobileTopics",
    icon: DashboardIcon,
    component: MobileTopics,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/topics-w",
    name: "WebTopics",
    icon: DashboardIcon,
    component: WebTopics,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/pdf",
    name: "PDFViewer",
    icon: DashboardIcon,
    component: PDFViewer,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/viewer",
    name: "HTMLViewer",
    icon: DashboardIcon,
    component: HTMLViewer,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/contentviewer",
    name: "HTMLContentViewer",
    icon: DashboardIcon,
    component: HTMLContentViewer,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/player",
    name: "VideoViewer",
    icon: DashboardIcon,
    component: VideoViewer,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/activity",
    name: "ActivityViewer",
    icon: DashboardIcon,
    component: ActivityViewer,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/signup",
    name: "Signup",
    icon: DashboardIcon,
    component: Signup,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/cart",
    name: "cart",
    icon: DashboardIcon,
    component: ShoppingCart,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/payment-response",
    name: "PGResponse",
    icon: DashboardIcon,
    component: PGResponse,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/userdetails",
    name: "User Details",
    icon: DashboardIcon,
    component: UserDetails,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/boardgrade",
    name: "Board Grade",
    icon: DashboardIcon,
    component: BoardGrade,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/missions-schedules",
    name: "Schedules",
    icon: DashboardIcon,
    component: MissionsSchedules,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/assessment-tests",
    name: "Tests",
    icon: DashboardIcon,
    component: AssessmentTests,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/assessment-report",
    name: "Tests",
    icon: DashboardIcon,
    component: AssessmentTestReport,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/report/:testId",
    name: "Report",
    icon: DashboardIcon,
    component: TestReport,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/assessment-dashboard",
    name: "DashBoard",
    icon: DashboardIcon,
    component: AssementDashBoard,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/online-test",
    name: "TestViewer",
    icon: DashboardIcon,
    component: TestViewer,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
  {
    path: "/notfound",
    name: "notfound",
    icon: DashboardIcon,
    component: NotFound,
    layout: "",
    divider: null,
    submenu: [],
    collapse: false,
  },
];

export default inventoryRoutes;
