
// App Configs
export const FETCH_APPCONFIGS_START = 'FETCH_APPCONFIGS_START'
export const SET_APPCONFIGS = 'SET_APPCONFIGS'
export const FETCH_APPCONFIGS_FAILED = 'FETCH_APPCONFIGS_FAILED'
export const SET_APPTYPE = 'SET_APPTYPE'

// Post change password
export const POST_CHANGE_PASSWORD_START = "POST_CHANGE_PASSWORD_START"
export const POST_CHANGE_PASSWORD_SUCCESS = "POST_CHANGE_PASSWORD_SUCCESS"
export const POST_CHANGE_PASSWORD_FAILED = "POST_CHANGE_PASSWORD_FAILED"

//user
export const FETCH_USERINFO_START = 'FETCH_USERINFO_START'
export const SET_USERINFO = 'SET_USERINFO'
export const FETCH_USERINFO_FAILED = 'FETCH_USERINFO_FAILED'
export const SET_PROFILE_PICTURE = 'SET_PROFILE_PICTURE'

export const FETCH_USER_PROGRESS_START = 'FETCH_USER_PROGRESS_START'
export const SET_USER_PROGRESS = 'SET_USER_PROGRESS'
export const FETCH_USER_PROGRESS_FAILED = 'FETCH_USER_PROGRESS_FAILED'

export const AUTH_FAILED = 'AUTH_FAILED'
export const SET_USER_TYPE = 'SET_USER_TYPE'

//content
export const FETCH_CONTENTLIST_START = "FETCH_CONTENTLIST_START"
export const SET_CONTENTLIST = 'SET_CONTENTLIST'
export const FETCH_CONTENTLIST_FAILED = 'FETCH_CONTENTLIST_FAILED'

export const UPDATE_CONTENTLIST = 'UPDATE_CONTENTLIST'

// Fetch Content Rating
export const FETCH_CONTENT_RATING_START = "FETCH_CONTENT_RATING_START"
export const SET_CONTENT_RATING = "SET_CONTENT_RATING"
export const FETCH_CONTENT_RATING_FAILED = "FETCH_CONTENT_RATING_FAILED"

// post Content Rating
export const POST_CONTENT_RATING_START = "POST_CONTENT_RATING_START"
export const POST_CONTENT_RATING_SUCCESS = "POST_CONTENT_RATING_SUCCESS"
export const POST_CONTENT_RATING_FAILED = "POST_CONTENT_RATING_FAILED"

// post Content Access
export const POST_CONTENT_ACCESS_START = "POST_CONTENT_ACCESS_START"
export const POST_CONTENT_ACCESS_SUCCESS = "POST_CONTENT_ACCESS_SUCCESS"
export const POST_CONTENT_ACCESS_FAILED = "POST_CONTENT_ACCESS_FAILED"

//Post Test Attempt
export const POST_TEST_ATTEMPT_START = "POST_TEST_ATTEMPT_START"
export const POST_TEST_ATTEMPT_SUCCESS = "POST_TEST_ATTEMPT_SUCCESS"
export const POST_TEST_ATTEMPT_FAILED = "POST_TEST_ATTEMPT_FAILED"

//User Quest
export const FETCH_USERQUEST_LIST_START = 'FETCH_USERQUEST_LIST_START'
export const SET_USERQUEST_LIST = 'SET_USERQUEST_LIST'
export const FETCH_USERQUEST_LIST_FAILED = 'FETCH_USERQUEST_LIST_FAILED'
export const SET_CURRENT_QUEST_BY_ID = 'SET_CURRENT_QUEST_BY_ID'
export const SET_CURRENT_QUEST = "SET_CURRENT_QUEST"


export const SET_CURRENT_MISSION = "SET_CURRENT_MISSION"
export const SET_CURRENT_OPERATION = "SET_CURRENT_OPERATION"

// user current content
export const SET_CURRENT_CONTENT = "SET_CURRENT_CONTENT"
export const CLEAR_CURRENT_CONTENT = "CLEAR_CURRENT_CONTENT"
export const UPDATE_CURRENT_CONTENT = "UPDATE_CURRENT_CONTENT" //for marksObtained,cardSatus
export const UPDATE_ACCESS_DURATION = 'UPDATE_ACCESS_DURATION'

// user action Test
export const SET_CURRENT_TESTS = "SET_CURRENT_TESTS"
export const UPDATE_TEST_ATTEMPT_COUNT = "UPDATE_TEST_ATTEMPT_COUNT"
export const UPDATE_TEST_ATTEMPT_DURATION = "UPDATE_TEST_ATTEMPT_DURATION"
export const SET_CURRENT_TEST_HISTORY = "SET_CURRENT_TEST_HISTORY"

//questions
export const FETCH_TESTQUESTIONS_START = 'FETCH_TESTQUESTIONS_START'
export const SET_TESTQUESTIONS = "SET_TESTQUESTIONS"
export const FETCH_TESTQUESTIONS_FAILED = 'FETCH_TESTQUESTIONS_FAILED'
//answer
export const SET_TEST_ANSWERS = "SET_TEST_ANSWERS"

// update question answer
export const UPDATE_QUESTION_ANSWER = 'UPDATE_QUESTION_ANSWER'
export const UPDATE_ANSWER_DURATION = 'UPDATE_ANSWER_DURATION'

//Fetch Shared Data 
export const FETCH_SHARED_DATA_START = "FETCH_SHARED_DATA_START"
export const SET_SHARED_DATA = "SET_SHARED_DATA"
export const FETCH_SHARED_DATA_FAILED = "FETCH_SHARED_DATA_FAILED"

//Post Shared Data
export const POST_SHARED_DATA_START = "POST_SHARED_DATA_START"
export const POST_SHARED_DATA_SUCCESS = "POST_SHARED_DATA_SUCCESS"
export const POST_SHARED_DATA_FAILED = "POST_SHARED_DATA_FAILED"


//Fetch Leader Board
export const FETCH_LEADERBOARD_DATA_START = "FETCH_LEADERBOARD_DATA_START"
export const SET_LEADERBOARD_DATA = "SET_LEADERBOARD_DATA"
export const FETCH_LEADERBOARD_DATA_FAILED = "FETCH_LEADERBOARD_DATA_FAILED"
export const SHOW_LEADERBOARD_DIALOG = "SHOW_LEADERBOARD_DIALOG"

// Notification
export const FETCH_NOTIFICATION = "FETCH_NOTIFICATION"
export const SET_NOTIFICATION = "SET_NOTIFICATION"

// Quest Category
export const FETCH_QUESTCATEGORY = "FETCH_QUESTCATEGORY"
export const SET_QUESTCATEGORY = "SET_QUESTCATEGORY"
export const SET_QUEST_DETAILS = "SET_QUEST_DETAILS"

// Quest Product
export const FETCH_QUESTPRODUCTS = "FETCH_QUESTPRODUCTS"
export const SET_QUESTPRODUCTS = "SET_QUESTPRODUCTS"
export const SET_PRODUCT_DETAILS = "SET_PRODUCT_DETAILS"

// Register User
export const INIT_REGISTER_USER = "INIT_REGISTER_USER"
export const SET_FLNAME = "SET_FLNAME"
export const SET_USERNAME = "SET_USERNAME"
export const SET_USERNAME_PASSWORD = "SET_USERNAME_PASSWORD"
export const SET_SCHOOLNAME_CITY = "SET_SCHOOLNAME_CITY"
export const SET_MOBILE_NUMBER = "SET_MOBILE_NUMBER"
export const SET_USER_EMAIL = "SET_USER_EMAIL"
export const SET_PROMO_CODE = "SET_PROMO_CODE"
export const SET_UTM_DATA = "SET_UTM_DATA"

// Shopping Cart
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const CLEAR_CART = 'CLEAR_CART'
export const SET_CART_ITEMS = 'SET_CART_ITEMS'
export const SET_STORE_COUPON_CODE = 'SET_STORE_COUPON_CODE'
export const SET_STORE_COUPON_STATUS = 'SET_STORE_COUPON_STATUS'

// Payment Gatway
export const SET_PG_DATA = 'SET_PG_DATA'
export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS'

// Reset Password
export const SET_USER_verification_STATUS = "SET_USER_verification_STATUS"
export const SET_OTP_verification_STATUS = "SET_OTP_verification_STATUS"

// Platform
export const SET_PLATFORM = "SET_PLATFORM";

// Board Greade
export const SET_BOARD_GRADE = "SET_BOARD_GRADE";

//ProductBanner
export const SET_PRODUCT_BANNER = "SET_PRODUCT_BANNER";

//Raymond Products
export const SET_RAYMOND_PRODUCTS = "SET_RAYMOND_PRODUCTS";

//set goCodeprompt
export const SET_GO_CODER_PROMPT = 'SET_GO_CODER_PROMPT'