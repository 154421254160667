import * as actionTypes from "./actionTypes";
import axiosConfig from "util/axios";
let defaultBannerLists = [
  {
    bannerType: "Image",
    bannerURL:
      "https://questcontent.blob.core.windows.net/filecontainer/Banner/Banner_04.jpg",
  },
];
export const setProductBanner = (bannerLists) => {
  return {
    type: actionTypes.SET_PRODUCT_BANNER,
    payload: bannerLists,
  };
};

export const fetchProductBanner = (ProductId, institutionId = 1) => {
  return async (dispatch) => {
    axiosConfig()
      .get("/QuestContent/GetInstitutionBanner", {
        params: {
          ProductId: ProductId,
          InstitutionId: institutionId,
        },
      })
      .then((response) => {
        if (response.data.bannerLists.length === 0)
          dispatch(setProductBanner(defaultBannerLists));
        else dispatch(setProductBanner(response.data.bannerLists));
      })
      .catch((error) => {
        if (error.response.data) throw new Error(error.response.data.error);
        // throw new Error(error.message);
      });
  };
};
