import React, { useState } from "react";
//redux
import { connect } from 'react-redux'

// @material-ui/core
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from "@material-ui/core";
import Dialog from  'components/CustomComponents/Dialog/Dialog'

// @material-ui/icons
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';

import Button from "components/CustomComponents/CustomButtons/Button.js";
import Slide from '@material-ui/core/Slide';
import imagine1 from '../../../assets/img/GoCoderzPopup-01.jpg'
import { makeStyles } from '@material-ui/core/styles';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});



const useStyles = makeStyles({
  iconButton: {
    color:"#dad2d2"
  },
  modalDialog:{
    width:'95%',
    textAlign:'center',
  },
  modalContent:{
    padding:'0',
  }
});

export const GoCoderInstruction = (props) => {
  const [showThanksModal,setShowThanksModal] = useState(false)
  const classes = useStyles();
  if(showThanksModal){
    return <>
          <Dialog open={showThanksModal} maxWidth={"sm"} TransitionComponent={Transition}  className='goCoderModal'>
     <Box 
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="responsive-dialog-title">  <Typography variant="h5">Join the Go Coderz Coding Competition!</Typography></DialogTitle>

            <IconButton
            aria-label="close"
              onClick={props.onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 0,
                color: (theme) => theme.palette.grey[500],
            }}
            >
            <CloseIcon />
            </IconButton>
        </Box>
      
        <DialogContent>
            <DialogContentText>
            <Typography variant="subtitle1"> Thank you for participating in gocoderz!</Typography>
            
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button size='md' round outlined color="primary" onClick={() => {
              setShowThanksModal(false)
            }} >
            Ok
            </Button>
          
        </DialogActions>
        </Dialog>
    </>
  }
  return (
    <React.Fragment>
        <Box 
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          
          >
            <DialogTitle className={classes.modalDialog} id="responsive-dialog-title">  <Box fontSize={'20px'} fontWeight={'800'} marginLeft={'50px'} >Go Coderz - National Coding Competition!</Box></DialogTitle>

            <IconButton
              aria-label="close"
              onClick={props.onClose}
              className={classes.iconButton}
            >
            <CloseIcon  />
            </IconButton>
        </Box>
      
        <DialogContent className={classes.modalContent}>
            {/* <DialogContentText> */}
            <img src={imagine1} alt="..." height={'100%'} width={'100%'} />
            {/* <Typography variant="subtitle1"> Do you want to participate in a national coding competition organized with the World Book of Records (London, UK)? It features coding challenges to assess problem-solving, logical thinking, and programming skills, promoting innovation and supporting young coding talents. This is your chance to showcase your abilities and compete nationally!</Typography> */}
            
            {/* </DialogContentText> */}
        </DialogContent>
        <Box   display="flex"
          alignItems="center" 
          justifyContent='center'
          padding={'10px 0'}
          >
            <Button size='large'  round  color="primary" sx={{ fontSize: 34, fontWeight: 'medium' }}    onClick={() => {
                props.onClickAction('yes')
            }} >
               <Box fontSize={'20px'} fontWeight={'800'} >   Yes! I want to join the competition</Box>
         
            </Button>
      
        </Box>
    </React.Fragment >
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(GoCoderInstruction)