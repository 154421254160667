import React, { useEffect, useState } from 'react';
import { useLocation, Redirect, useHistory } from 'react-router-dom'
//Redux
import { useDispatch, useSelector } from 'react-redux'
//import reducer 
import * as questActions from 'store/actions/index'

// material-ui components
import { useTheme } from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
//Custom Components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import Button from 'components/CustomComponents/CustomButtons/Button'
//Lottie
import Lottie from 'react-lottie';
import animationData from 'assets/lotties/payment-successful';
import loaderAnimationData from 'assets/lotties/loader';


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
const loaderOptions = {
    loop: true,
    autoplay: true,
    animationData: loaderAnimationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const PGResponse = (props) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const history = useHistory();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const userinfo = useSelector(state => state.user.userinfo)
    const [fetchUserInfo, setFetchUserInfo] = useState(false)


    // const location = useLocation().pathname.split("/");
    // console.log(location.pop());

    useEffect(() => {
        dispatch(questActions.ClearCart())
        dispatch(questActions.initUserInfo()).then(() => {
            dispatch(questActions.initUserQuestList(true)).then(() => {
                setFetchUserInfo(true)
            })
        })
    }, [])


    useEffect(() => {
        if (fetchUserInfo)
            setTimeout(() => {
                navigateTo(userinfo.showDashboard)
            }, 3000);

    }, [fetchUserInfo])


    // navigate To
    const navigateTo = (showDashboard) => {
        // if (showDashboard)
        //     return history.push('/products')
        return history.push('/home')
    }





    return (
        <div className='page-container background-home'>
            <CssBaseline />

            <GridContainer >
                <GridItem xs={12} sm={12} md={12} >
                    <div style={{ height: '100%', width: '100%', maxWidth: sm ? '100%' : '40vw', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Lottie options={defaultOptions}
                            height={'100%'}
                            width={'100%'} />
                    </div>
                </GridItem>

                <GridItem xs={12} sm={12} md={12} >
                    <div style={{ height: '100%', width: '40vh', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Lottie options={loaderOptions}
                            height={'100%'}
                            width={'100%'} />
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} >
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button size="sm" color="secondary">Redirect </Button>
                    </div>
                </GridItem>
            </GridContainer>



        </div>
    );
}

export default PGResponse;