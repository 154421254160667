import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useLocation } from "react-router-dom";
// // core components
// import PageNavigation from 'components/CustomComponents/Navbars/PageNavigation'

//import style
import "assets/css/loader.css";

const DocView = (props) => {
  let location = useLocation();
  const [loading, setLoading] = useState(true);
  const [display, setDisplay] = useState("none");

  const hideSpinner = () => {
    setLoading(false);
    setDisplay("block");
  };

  useEffect(() => {
    window.addEventListener("message", eventListener);
    // clean up
    return () => window.removeEventListener("message", eventListener);
  }, []);

  const eventListener = (event) => {
    if (event.data !== null && typeof event.data !== "undefined") {
      try {
        let actObj = JSON.parse(event.data);
        if (
          actObj.Method == "FinishTest" ||
          actObj.Method == "AbortTest" ||
          actObj.Method == "TestAbort" ||
          actObj.Method == "Back"
        ) {
          return props.back
            ? props.history.push(props.back)
            : props.history.replace("/assessment-tests");
        }
      } catch (error) {}
    }
  };

  return (
    <React.Fragment>
      {/*-----PageNavigation----- */}
      <CssBaseline />

      {/*-----page-container----- */}
      <div className="page-container-5015_Activity">
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="loader">Loading...</div>
          </div>
        ) : null}
        <iframe
          title="HTMLViewer"
          style={{
            position: "relative",
            left: 0,
            // bottom: 0,
            right: 0,
            width: "100%",
            minHeight: "100vh",
            border: "none",
            display: display,
          }}
          onLoad={hideSpinner}
          src={location.state.testLink || ""}
        />
      </div>
    </React.Fragment>
  );
};

export default DocView;
