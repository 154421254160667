import React, { useState } from "react";
import "./TableAnalysis.css";
import { TablePagination } from "@material-ui/core";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { convertSeconds } from "util/commonfunctions";
import { serverDjangoURL } from "variables/ServerURL";

const dialogStyles = {
  paper: {
    borderRadius: "8px",
    overflow: "hidden",
  },
};

const headerStyles = {
  root: {
    backgroundColor: "#f5f5f5",
    borderBottom: "1px solid #e0e0e0",
    padding: "16px 24px",
  },
  title: {
    fontSize: "18px",
    fontWeight: 600,
    margin: 0,
  },
};

const tableStyles = {
  container: {
    maxHeight: "calc(100vh - 280px)",
  },
  headerCell: {
    backgroundColor: "#f5f5f5",
    fontWeight: 600,
    padding: "12px 16px",
  },
  cell: {
    padding: "12px 16px",
  },
  row: {
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
};
const CommonAnalysisTable = ({
  data,
  heading,
  style,
  firstTdStyle,
  onModalOpen,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [detailPage, setDetailPage] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const userInfo = useSelector((state) => state.user.userinfo);

  function getKeyValue(obj, key) {
    return obj[key] !== undefined ? obj[key] : "-";
  }

  const fetchModalData = async (testId, type, pageNum = 1) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${serverDjangoURL}/api/search/getTestDetailByUserAndTestId/`,
        {
          userId: userInfo.userId,
          testId: testId,
          range: type,
          pageNumber: pageNum,
          pageSize: rowsPerPage,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 && response.data) {
        setModalData((prevData) => ({
          ...prevData,
          details: response.data,
        }));
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (err) {
      setError(
        err.response?.data?.message || err.message || "An error occurred"
      );
      console.error("API Error:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (e) => {
    const clickedElement = e.target;
    if (clickedElement.classList.contains("clickShowModal")) {
      const modalInfo = {
        testId: clickedElement.getAttribute("data-id"),
        type: clickedElement.getAttribute("data-type"),
        name: clickedElement.getAttribute("data-name"),
        count: clickedElement.getAttribute("data-count"),
      };
      console.log("modalInfo", modalInfo);
      if (modalInfo.count === "0") {
        return;
      }
      setModalData(modalInfo);
      setDetailPage(0); // Reset detail page when opening new modal
      setModalOpen(true);
      fetchModalData(modalInfo.testId, modalInfo.type, 1);
    }
  };

  const handleClose = () => {
    setModalOpen(false);
    setModalData(null);
    setDetailPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDetailPage = (event, newPage) => {
    setDetailPage(newPage);
    if (modalData?.testId && modalData?.type) {
      fetchModalData(modalData.testId, modalData.type, newPage + 1);
    }
  };

  if (data.length === 0) {
    return null;
  }

  const questions = data[0].questions;
  const indexOfFirstItem = page * rowsPerPage;
  const indexOfLastItem = indexOfFirstItem + rowsPerPage;
  const currentItems = questions.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="table-container" style={style}>
      {heading && <h2>{heading}</h2>}
      <table>
        <thead>
          <tr>
            {Object.keys(data[0].map).map((key) => (
              <th key={key}>{data[0].map[key]}</th>
            ))}
          </tr>
        </thead>
        <tbody onClick={handleClick}>
          {currentItems.map((question, questionIndex) => (
            <tr key={questionIndex}>
              {Object.keys(data[0].map).map((key, keyIndex) => (
                <td
                  key={key}
                  style={
                    keyIndex === 0 && firstTdStyle ? firstTdStyle : undefined
                  }
                  dangerouslySetInnerHTML={{
                    __html: getKeyValue(question, key),
                  }}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {questions.length > 5 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={questions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      <Dialog
        open={modalOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: dialogStyles.paper }}
      >
        <DialogTitle style={headerStyles.root}>
          <h2 style={headerStyles.title}>
            {modalData?.name || "Test Details"}
          </h2>
        </DialogTitle>

        <DialogContent style={{ padding: 0 }}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "256px",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <TableContainer style={tableStyles.container}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell style={tableStyles.headerCell}>
                        UserName
                      </TableCell>
                      <TableCell
                        style={{
                          ...tableStyles.headerCell,
                          textAlign: "right",
                        }}
                      >
                        Total Questions
                      </TableCell>
                      <TableCell
                        style={{
                          ...tableStyles.headerCell,
                          textAlign: "right",
                        }}
                      >
                        Total Marks
                      </TableCell>
                      <TableCell
                        style={{
                          ...tableStyles.headerCell,
                          textAlign: "right",
                        }}
                      >
                        Marks
                      </TableCell>
                      <TableCell
                        style={{
                          ...tableStyles.headerCell,
                          textAlign: "right",
                        }}
                      >
                        Attempted / Total Duration (Min)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {modalData?.details?.data?.map((row) => (
                      <TableRow
                        key={row.MissionTestSummaryResultId}
                        hover
                        style={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell style={tableStyles.cell}>
                          {row.userDetails?.userName}
                        </TableCell>
                        <TableCell
                          style={{ ...tableStyles.cell, textAlign: "right" }}
                        >
                          {row.TotalQuestion}
                        </TableCell>
                        <TableCell
                          style={{ ...tableStyles.cell, textAlign: "right" }}
                        >
                          {row.TotalMarks}
                        </TableCell>
                        <TableCell
                          style={{ ...tableStyles.cell, textAlign: "right" }}
                        >
                          {row.FirstAttemptMark}
                        </TableCell>
                        <TableCell
                          style={{ ...tableStyles.cell, textAlign: "right" }}
                        >
                          <span style={{ color: "#1976d2" }}>
                            {convertSeconds(row.AttemptedDuration)}
                          </span>
                          {" / "}
                          <span style={{ color: "#666" }}>
                            {row.ActualDuration}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {modalData?.details?.totalRecords > rowsPerPage && (
                <div style={{ borderTop: "1px solid #e0e0e0" }}>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={modalData.details.totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={detailPage}
                    onPageChange={handleChangeDetailPage}
                    style={{ padding: "8px 16px" }}
                  />
                </div>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CommonAnalysisTable;
