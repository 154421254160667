import React, { Suspense, lazy, useEffect ,useState} from "react";
import { connect } from 'react-redux'

// @material-ui/core
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Hidden from "@material-ui/core/Hidden";

//Custom Components
import GridItem from "components/CustomComponents/Grid/GridItem.js";
import GridContainer from "components/CustomComponents/Grid/GridContainer.js"
import PageNavigation from 'components/CustomComponents/Navbars/PageNavigation'
// import WebTopics from 'components/Views/Tasks/Topics/WebTopics'
import OperationCards from 'components/Views/Tasks/Operation/Operation'

import FileUpload from "components/CustomComponents/FileUpload/FileUpload";
import Grid from "@material-ui/core/Grid";
import axiosConfig from "util/axios";
import { serverDjangoURL } from "variables/ServerURL";
// styles
import useStyles from "assets/jss/components/views/taskStyle";
import './style.css'

const WebTopics = lazy(() => import('components/Views/Tasks/Topics/WebTopics'));


const Operations = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const [masterClassData, setMasterClassData] = useState([])

    const getGoCoderDetails = async() => {
        await axiosConfig(serverDjangoURL).get('/api/active_masterclass_links/').then(async response => {
            // await dispatch(setUserInfo(response.data))
            setMasterClassData(response.data)
        }).catch(error => {
            if (error.response.data)
                throw new Error(error.response.data.error)
            throw new Error(error.message);
        });
    }

    useEffect(() => {
        props.mission.missionName === "GO_CODERZ" &&  getGoCoderDetails()
    }, [])


    return (
        <React.Fragment>
            {/*-----PageNavigation----- */}
            <PageNavigation logoText={props.mission.missionDisplayName} back={'/home'}  className={classes.divPageNavigation}>
                <Typography style={{fontSize:10,color:'#000000'}}>
                    {`(${props.mission.videoCount>0 ? props.mission.videoCount +" Videos,":''} 
                   ${props.mission.testCount>0 ? props.mission.testCount+' Tests,':''} 
                   ${props.mission.webContentCount>0 ? props.mission.webContentCount+' Webs':''} 
                   ${props.mission.activityCount>0 ?props.mission.activityCount+' Activities':''})`}
                </Typography>
            </PageNavigation>

            {/*-----page-container----- */}
            <div className="task-container">

                {sm // mobile 
                    ? <GridContainer >
                        <GridItem xs={12} sm={12} md={4} className="operation-container">
                            <div style={{height: '92vh', overflow: "overlay", paddingLeft: 30, paddingRight: 30,paddingBottom:20 }}> {/* height: '90vh' */}
                                <OperationCards onClick={() => props.history.push({ pathname: '/topics' })} ></OperationCards>
                            </div>
                        </GridItem>
                    </GridContainer>

                    : <GridContainer>
                        <GridItem xs={12} sm={12} md={4} className="operation-container">
                            <div style={{ height: '92vh', overflow: "overlay", padding: '5px 30px 20px 30px', marginRight: '-30px' }} className='scroll'>
                                <OperationCards onClick={() => { }} ></OperationCards>
                            </div>
                        </GridItem>

                        <GridItem xs={12} sm={12} md={8} className="topics-container">
                            <Typography variant="body1" className={classes.topicTitle}>
                                {props.operation.operationDisplayName}
                            </Typography>
                            <div style={{ height: '87vh', padding: 15, paddingTop: 0, marginRight: '-15px',overflow: "overlay",scrollBehavior: 'smooth' }} className='scroll'>
                                 <Suspense fallback={<div>Loading...</div>}>
                                    <WebTopics  userInfo={props?.userInfo}/>
                                    {props.mission.missionName === "GO_CODERZ"  ? (
                                        <>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={6}>
                                            <FileUpload userInfo={props?.userInfo}/>
                                            </Grid>
                                            {masterClassData.map((item) => {
                                            return (
                                                <Grid item xs={12} sm={12} md={6}>
                                                <div className={classes.joinMasterClassCard}>
                                                    <div className={classes.logoContainer}>
                                                    {item.is_live ? (
                                                        <div className={classes.liveclass}>
                                                        <div className={classes.blinkingRedDot}></div>Live
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    </div>
                                                    <p className={classes.joinMasterClassText}>
                                                    {" "}
                                                    {item.masterclass_name}
                                                    </p>
                                                    <a
                                                    href={item.masterclass_link}
                                                    target="_blank"
                                                    className={classes.joinNow}
                                                    >
                                                    Join Now
                                                    </a>
                                                </div>
                                                </Grid>
                                            );
                                            })}
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <div className={classes.instructionContainer}>
                                            <h3>Instruction</h3>

                                            <ul>
                                                <li>1. Click on the Join Now button to join the Masterclass.</li>
                                                <li>
                                                2. Once you join the Masterclass, you will be redirected to the
                                                Masterclass page.
                                                </li>
                                                <li>
                                                3. You can start learning by clicking on the Start Learning
                                                button.
                                                </li>
                                            </ul>
                                            </div>
                                        </Grid>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </Suspense>


                            </div>
                        </GridItem>
                    </GridContainer>
                }
            </div>
        </React.Fragment>
    )
}


const mapSateToProps = state => {
    return {
        mission: state.content.current_mission,
        operation: state.content.current_operation,
        userInfo: state.user.userinfo,
    }
}


export default connect(mapSateToProps)(Operations);