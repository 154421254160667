import * as actionTypes from 'store/actions/actionTypes'

const initialState = {
    questions: {
        "qadd": [],
        "qimgadd": [],
        "accessDate": ""
    },
    answers: [],
    error: false,
    loading: true,
    post_test_loading: false,
}


const reducer = (state = initialState, action) => {
    switch (action.type) {

        // -- Fetch Test Attempt
        case actionTypes.FETCH_TESTQUESTIONS_START:
            return {
                ...state,
                loading: true
            }
        case actionTypes.SET_TESTQUESTIONS:
            return {
                ...state,
                questions: action.questions,
                error: false,
                loading: false
            }
        case actionTypes.FETCH_TESTQUESTIONS_FAILED:
            return {
                ...state,
                error: true,
                loading: false
            }

        // Set Test Answer
        case actionTypes.SET_TEST_ANSWERS:
            const initAnswers = action.answers.qadd.map(current_question => {
                const answer = {
                    "testResultDetailsId": "", // Don't set this
                    "questionId": current_question.questionId,
                    "isQuestionSkipped": "0",
                    "isAnsweredCorrect": "0",
                    "attemptedAnswer": "",
                    "questionMarks": "" + current_question.correctMark,
                    "questionMarksObtained": "0",
                    "questionDuration": 0,
                    "answerOrderSeq": ""  // Don't set this
                };
                return answer
            })
            return {
                ...state,
                answers: initAnswers,
            }

        // update question & answer
        case actionTypes.UPDATE_QUESTION_ANSWER:

            const testQuestion = state.questions.qadd.map(currentQuestion => {
                let qsn = { ...currentQuestion }
                if (action.questionId === qsn.questionId) {
                    let optionItems = [...qsn.options.optionItems]
                    qsn.options.optionItems = optionItems.map(opt => {
                        let newOpt = { ...opt }
                        if (qsn.questionTypeCode === "QT01" || qsn.questionTypeCode === "QT04" || qsn.questionTypeCode === "QT09")
                            newOpt['ischecked'] = false
                        if (action.answer === newOpt.id) {
                            if (qsn.questionTypeCode === "QT02")
                                newOpt['ischecked'] = !newOpt['ischecked']
                            else
                                newOpt['ischecked'] = true
                        }
                        return newOpt
                    })
                }
                return qsn
            })

            const testAnswers = state.answers.map(current_answers => {
                if (action.questionId === current_answers.questionId) {
                    const current_question = testQuestion.filter(question => question.questionId === current_answers.questionId).reduce((acc, cv) => { return acc = cv }, {})
                    const attempted_answer = current_question.options.optionItems.filter(option => option.ischecked === true).map(op => op.id).sort().join(",")
                    const answered_correct = attempted_answer === current_question.answer.split(",").sort().join(",")
                    current_answers.isQuestionSkipped = attempted_answer?"1":"0";
                    current_answers.isAnsweredCorrect = answered_correct ? "1" : "0";
                    current_answers.attemptedAnswer = attempted_answer
                    current_answers.questionMarksObtained = answered_correct ?""+current_question.correctMark : "0"
                }
                return current_answers
            })

            return {
                ...state,
                questions: { ...state.questions, "qadd": testQuestion },
                answers: [...testAnswers],
            }

        // update ANSWER DURATION
        case actionTypes.UPDATE_ANSWER_DURATION:
            const updatedTimeAnswers = [...state.answers].map(current_answers => {
                let newCurrent_answers = { ...current_answers }
                if (action.questionId === newCurrent_answers.questionId) {
                    newCurrent_answers.questionDuration = newCurrent_answers.questionDuration + action.duration
                }
                return newCurrent_answers
            })
            return {
                ...state,
                answers: [...updatedTimeAnswers],
            }


        // -- Post Test Attempt
        case actionTypes.POST_TEST_ATTEMPT_START:
            return {
                ...state,
                error: false,
                post_test_loading: true
            }

        case actionTypes.POST_TEST_ATTEMPT_SUCCESS:
            return {
                ...state,
                error: false,
                post_test_loading: false
            }

        case actionTypes.POST_TEST_ATTEMPT_FAILED:
            return {
                ...state,
                error: true,
                post_test_loading: false
            }
        default:
            return state;
    }
}

export default reducer