import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import RemarkMathPlugin from 'remark-math';
import { BlockMath, InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';

const _mapProps = (props) => ({
  ...props,
  escapeHtml: false,
  plugins: [
    RemarkMathPlugin
  ],
  renderers: {
    ...props.renderers,
    math: ({ value }) => <BlockMath>{value}</BlockMath>,
    inlineMath: ({ value }) => <InlineMath>{value}</InlineMath>
  }
});

const Markdown = (props) => {

  // let Input = props.children.replace(/(<p[^>]+?>|<p>)/, "<div>")
  // Input=Input.replace(/(<\/p>)/, "</div>")
  // Input=Input.replace(/<\/?span[^>]*>/g,"")
  let Input = props.children
  .replace(/(<p[^>]+?>|<p>)/g, "<div>") // Replace <p> with <div>
  .replace(/(<\/p>)/g, "</div><br>") // Replace </p> with </div><br>
  .replace(/<\/?span[^>]*>/g, "") // Remove all <span> tags
  // .replace(/<img((?!style)[^>]+)>/g, '<img style="height: 100%;" $1>') // Add height: 100% without overwriting existing styles
  .replace(/<img([^>]+)style="([^"]*)"/g, '<img$1style="height: auto; $2"'); // Append height: 100% if style attribute already exists


  return <ReactMarkdown {..._mapProps(props)} children={`<div>${Input}</div>`} />
};

export default Markdown;